@import url("https://use.typekit.net/mic4cbd.css");
/* https://res.cloudinary.com/dgps1radv/image/upload/c_fill,w_200/mto5squ8abpmd02zymev */
$red: #b70e1c;
$black: #222;
$borderWidth: 4px;
$paddingY: 0.35em;

$sigFont: dalliance-roman, sans-serif;
.site-content {
  background-color: #ffffff;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 40' width='80' height='40'%3E%3Cpath fill='%239C92AC' fill-opacity='0.06' d='M0 40a19.96 19.96 0 0 1 5.9-14.11 20.17 20.17 0 0 1 19.44-5.2A20 20 0 0 1 20.2 40H0zM65.32.75A20.02 20.02 0 0 1 40.8 25.26 20.02 20.02 0 0 1 65.32.76zM.07 0h20.1l-.08.07A20.02 20.02 0 0 1 .75 5.25 20.08 20.08 0 0 1 .07 0zm1.94 40h2.53l4.26-4.24v-9.78A17.96 17.96 0 0 0 2 40zm5.38 0h9.8a17.98 17.98 0 0 0 6.67-16.42L7.4 40zm3.43-15.42v9.17l11.62-11.59c-3.97-.5-8.08.3-11.62 2.42zm32.86-.78A18 18 0 0 0 63.85 3.63L43.68 23.8zm7.2-19.17v9.15L62.43 2.22c-3.96-.5-8.05.3-11.57 2.4zm-3.49 2.72c-4.1 4.1-5.81 9.69-5.13 15.03l6.61-6.6V6.02c-.51.41-1 .85-1.48 1.33zM17.18 0H7.42L3.64 3.78A18 18 0 0 0 17.18 0zM2.08 0c-.01.8.04 1.58.14 2.37L4.59 0H2.07z'%3E%3C/path%3E%3C/svg%3E");
}
// .gourmet88 {
//   background: url(https://gourmet88.com//wp-content/uploads/2012/02/gourmet88-background-image-1.jpg) no-repeat;
//   background-size: contain;
// }
.sipanglendale, .sipannorthridge {
  font-family: Helvetica, sans-serif;
  img.nav-bar-logo {
    display: block;
    // padding-left: 0.8em;
  }
  .cart.minimized {
    background-color: #fafff0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg stroke='%23CCC' stroke-width='0' %3E%3Crect fill='%23dae3f5' x='-60' y='-60' width='110' height='240'/%3E%3C/g%3E%3C/svg%3E");
    padding-bottom: 0.35em;
    border: none;
    /* background: black; */
    border-bottom: 2px solid rgba(255,255,255,0.8);
    /* background: repeating-linear-gradient(
    90deg,
    #dd0016,
    #dd0016 40px,
    #eee 40px,
    #eee 80px
    ); */
  }

  .landing-page, .top-banner {
    position: relative;
    font-family: $sigFont;
    /* color: white; */
    /* text-shadow: 0px 0px 4px rgba(255,255,255,1); */
    padding: 1em 0;
    margin: 0;
    display: block;
    min-height: 200px;
    /* max-height: 50vh; */
    // background: url(https://image.shutterstock.com/z/stock-photo-chinese-food-on-dark-wooden-table-noodles-fried-rice-dumplings-stir-fry-chicken-dim-sum-466836857.jpg);
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_scale,f_auto,w_1920/v1549662741/danstuff/8568c5053b34cd6a1ed9bc8aca4b8313-w2880-02.jpg);
    background-size: cover;
    background-position: center 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
  }
  .landing-page .text {
    font-family: Helvetica, sans-serif;
    color: white;
    background: rgba(0,0,0,0.3);
    padding: 0.5em 0.8em;
    text-align: center;
    h1 {
      font-weight: 600;
    }
    .location {
      .address {
        font-size: 0.6em;
      }
    }
  }
  .cashback-badge {

    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg width='180' height='180' viewBox='0 0 180 180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M81.28 88H68.413l19.298 19.298L81.28 88zm2.107 0h13.226L90 107.838 83.387 88zm15.334 0h12.866l-19.298 19.298L98.72 88zm-32.927-2.207L73.586 78h32.827l.5.5 7.294 7.293L115.414 87l-24.707 24.707-.707.707L64.586 87l1.207-1.207zm2.62.207L74 80.414 79.586 86H68.414zm16 0L90 80.414 95.586 86H84.414zm16 0L106 80.414 111.586 86h-11.172zm-8-6h11.173L98 85.586 92.414 80zM82 85.586L87.586 80H76.414L82 85.586zM17.414 0L.707 16.707 0 17.414V0h17.414zM4.28 0L0 12.838V0h4.28zm10.306 0L2.288 12.298 6.388 0h8.198zM180 17.414L162.586 0H180v17.414zM165.414 0l12.298 12.298L173.612 0h-8.198zM180 12.838L175.72 0H180v12.838zM0 163h16.413l.5.5 7.294 7.293L25.414 172l-8 8H0v-17zm0 10h6.613l-2.334 7H0v-7zm14.586 7l7-7H8.72l-2.333 7h8.2zM0 165.414L5.586 171H0v-5.586zM10.414 171L16 165.414 21.586 171H10.414zm-8-6h11.172L8 170.586 2.414 165zM180 163h-16.413l-7.794 7.793-1.207 1.207 8 8H180v-17zm-14.586 17l-7-7h12.865l2.333 7h-8.2zM180 173h-6.613l2.334 7H180v-7zm-21.586-2l5.586-5.586 5.586 5.586h-11.172zM180 165.414L174.414 171H180v-5.586zm-8 5.172l5.586-5.586h-11.172l5.586 5.586zM152.933 25.653l1.414 1.414-33.94 33.942-1.416-1.416 33.943-33.94zm1.414 127.28l-1.414 1.414-33.942-33.94 1.416-1.416 33.94 33.943zm-127.28 1.414l-1.414-1.414 33.94-33.942 1.416 1.416-33.943 33.94zm-1.414-127.28l1.414-1.414 33.942 33.94-1.416 1.416-33.94-33.943zM0 85c2.21 0 4 1.79 4 4s-1.79 4-4 4v-8zm180 0c-2.21 0-4 1.79-4 4s1.79 4 4 4v-8zM94 0c0 2.21-1.79 4-4 4s-4-1.79-4-4h8zm0 180c0-2.21-1.79-4-4-4s-4 1.79-4 4h8z' fill='%23ffde31' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-position: 0% 50%;
    padding: 1em 0;
    text-align: center;
    display: flex;
    // font-style: italic;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    .content {
      position: relative;
      top: -0.05em;
    }
    .percentage {
      // font-size: 0.6em;
      font-family: $sigFont;
      font-size: 1.8em;
    }
    .rewards {
      // font-size: 0.4em;
    }
  }


  a.checkout-button.button {
    background: $red;

    font-weight: 600;
    box-shadow: none;
    border: 2px solid #eee;

  }

  /* .category__header {
  font-family: cursive;

} */
.category-name .text {
  margin-left: -5px;
  font-family: $sigFont;
  font-weight: 400;
  font-size: 2.3em;
  color: $red;
  background: none;
  position: relative;
  z-index: 20;
}
// .category-name .text::before {
//   content: " ";
//   display: inline-block;
//   background: url(https://afag.imgix.net/sipan-bakery-glendale/easter.gif?w=400) no-repeat;
//   background-size: contain;
//   width: 80px;
//   height: 200px;
//   position: absolute;
//   left: -10px;
//   top: -20px;
//   z-index: -10
// }
// .category-name .text::before {
//   content: " ";
//   display: inline-block;
//   background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1549516034/danstuff/Artboard_4sipan.png) no-repeat;
//   background-size: contain;
//   width: 50px;
//   height: 50px;
//   position: absolute;
//   left: -20px;
//   top: 0px;
// }

.top-banner {
  // height: 30vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
  // color: white;
  h1 {
    font-weight: 600;
    font-size: 2em;
  }
  // text-shadow: 1px 1px 0 $red;
}

.pages-nav {
  font-family: $sigFont;
  background: #000;
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 480px) {
    font-size: 4vw;
  }
}

a.page-link {
  text-decoration: none;
  color: white;
  display: block;
  padding: $paddingY 0.3em;
  padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
  // box-sizing: border-box;
  border-bottom: $borderWidth solid #000;
  &:hover, &.active {
    color: $red;
    border-bottom: $borderWidth solid $red;
  }
}

.full-menu-link-wrapper {
  padding: 1em 0;
  text-align: center;
  a.button {
    background: $red;
    color: white;
  }
}
  .menu-item {
    header {
      // font-family: $sigFont;
      // font-size: 1.2em;
    }
  }

}
